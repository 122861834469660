footer {
  
  .upper {
    margin-bottom:0;

  }
  a {
    font-weight:400;
  }

  .disclaimer {
    @include font-size(12);
    padding-top:1rem;
    padding-bottom:1rem;
    margin-bottom:0;

    ul {
      list-style-type:none;
      margin:0;
      padding:0;
      display:flex;
      justify-content:center;
      flex-wrap:wrap;

      li + li:before {
        content:'';
        display:inline-block;
        vertical-align:middle;
        height:100%;
        width:10px;
        border-left:1px solid white;
        margin-left:10px;
      }
    }
    
    a {
      color:white;
    }
  }
}
