// bg clipping at gutter for cols
[class*="col"] {
  &[class*="bg-"] {
    background-clip:content-box;
  }
}

// Pulls information for each breakpoint
@each $size, $width in $breakpoints {
  
  // Upon collapse of cols at the appropriate breakpoint:
  @include mq-max-width($width) {

    // adds a margin to the top cols that come after cols
    [class^='col-#{$size}'] + [class^='col-#{$size}'] {
      margin-top:($cspacer * 2); 
    }

    // adds padding to the child div of the col to even spacing
    .container [class^="col-#{$size}"] > div {
      padding-left:$cspacer !important;
      padding-right:$cspacer !important;
    }

    // removes padding for container within fluid container
    .container-fluid .container {
      padding:0;
    }

    // switches bg clipping back to the edge of padding
    [class^="col-#{$size}"] {
      &[class*="bg-"] {
        background-clip:padding-box !important;
      }
    }
  }
}